import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { toast } from "react-toastify";
import { IntlProvider } from 'react-intl'
import "react-toastify/dist/ReactToastify.css";
import './App.scss';
import "react-datepicker/dist/react-datepicker.css";
const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./components/Login/Login'));
const Page404 = React.lazy(() => import('./views/Pages/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500'));


toast.configure({
  position: "bottom-right",
  autoClose: 5000,
  closeOnClick: true,
  rtl: false,
  draggable: false,
  pauseOnHover: true
});

class App extends Component {

  render() {
    return (
      <IntlProvider locale="en-US">
        <HashRouter>
            <React.Suspense fallback={loading()}>
              <Switch>
                <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
                <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
                <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
                <Route path="/" name="Home" render={props => <DefaultLayout {...props}/>} />
              </Switch>
            </React.Suspense>
        </HashRouter>
      </IntlProvider>
    );
  }
}

export default App;
